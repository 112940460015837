// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apropos-js": () => import("./../../../src/pages/apropos.js" /* webpackChunkName: "component---src-pages-apropos-js" */),
  "component---src-pages-atelier-js": () => import("./../../../src/pages/atelier.js" /* webpackChunkName: "component---src-pages-atelier-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gunnar-js": () => import("./../../../src/pages/gunnar.js" /* webpackChunkName: "component---src-pages-gunnar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-passepartouts-js": () => import("./../../../src/pages/passepartouts.js" /* webpackChunkName: "component---src-pages-passepartouts-js" */)
}

