module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-hivahoa-creation","short_name":"HivaHoaCreation","start_url":"/","background_color":"rgb(30, 75, 55)","theme_color":"rgb(30, 75, 55)","display":"minimal-ui","icon":"src/images/logo_hhc.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"31651a9f59ff4a991cfe6b81b1f3d44a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
